<template>
    <el-timeline class="TimeLine">
        <div class="TimeLine-search">
            <el-input
                v-model="queryForm.orderNo"
                placeholder="请输入提单号或报关单号"
                suffix-icon="el-icon-search"
                @change="handleSubmit"
            ></el-input>
        </div>
        <template v-if="timeLineList.length">
            <div class="result">物流查询结果</div>
            <div class="no">单号：{{ queryForm.orderNo }}</div>
            <el-timeline-item
                v-for="(item, index) in timeLineList"
                :key="index"
                :color="index === 0 ? '#0bbd87' : '#e4e7ed'"
            >
                <div class="TimeLine-item">
                    <div class="cur">
                        <!-- 【{{ item.curNode }}】 -->
                        {{ item.deal_time }}
                    </div>
                    <div class="location">{{ item.status_cn }}
                        <!-- 您的货物目前正在 -->
                        <!-- <span >{{ item.status_cn }}</span> -->
                        <!-- 下一节点预计
                        <span>【{{ item.nextTimestamp }}】</span>
                        在
                        <span>【{{ item.nextNode }}】</span>
                        开始处理 -->
                    </div>
                    <div class="desc">{{ item.location_cn }}</div>
                    <div class="remark">{{ item.remark1_cn }}</div>
                    
                </div>
            </el-timeline-item>
        </template>
        <template v-else>
            <div class="TimeLine-html" v-html="htmlLogistics"></div>
        </template>
    </el-timeline>
</template>

<script>
export default {
    name: 'TimeLine',
    data() {
        return {
            queryForm: {
                orderNo: ''
            },
            timeLineList: [],
            htmlLogistics: ''
        }
    },
    mounted () {
    },
    methods: {
        async getTimeLine() {
            const { orderNo } = this.queryForm;
            const formData = new FormData();
            formData.append('airno', orderNo);
            const { rows } = await this.$request.post(`/timeLine/api`, formData);
            if(rows.length) {
                this.timeLineList = rows;
            } else {
                const formData1 = new FormData();
                formData1.append('airno', orderNo);
                formData1.append('txtOrderNo', orderNo);
                const res = await this.$request.post(`/logistics/api`, formData1);
                this.htmlLogistics = res;
                console.log(res);
            }
        },
        handleSubmit() {
            const { orderNo } = this.queryForm;
            if(orderNo) {
                this.timeLineList = [];
                this.htmlLogistics = '';
                this.getTimeLine();
            } else {
                this.$message.warning('请输入提单号或者报关单号查询');
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.TimeLine{
    width: 100%;
    height: 100%;
    .query-form{
        margin-bottom: 40px;
        .el-input{
            width: 100%;
        }
        ::v-deep(.el-button){
            color: #fff;
            span {
                color: #fff;
            }
        }
    }
    .result{
        margin: 15px 0;
        font-size: 0.24rem;
    }
    .no{
        margin-bottom: 30px;
        font-size: 0.3rem;
    }
    &-item{
        line-height: 24px;
        .cur{

        }
        .location{
            font-weight: 700;
            color: #333;
        }
        .desc{
            color: #666;
        }
        .remark{
            color: #999;
        }
    }
    &-html{
        margin-top: 10px;
    }
}
</style>
<style lang="scss">

.wuliuchaxun{
    a{
        display: none;
    }
    .zgwuliu-list{
        margin: 0 auto;
        margin-top: 0px;
        padding: 15px 0;
        overflow: hidden;
        ul {
            li{
                width: 90%;
                padding: 15px 40px;
                // overflow: hidden;
                font-size: 16px;
                line-height: 30px;
                display: flex;
                flex-direction: column;
                position: relative;
                &::before{
                    content: '';
                    position: absolute;
                    left: 6px;
                    bottom: -20px;
                    transform: translateX(-50%);
                    background-color: #e4e7ed;
                    width: 2px;
                    height: 80px;
                }
                span{
                    &:nth-of-type(2) {
                        width: 12px;
                        height: 12px;
                        border-radius: 12px;
                        background-color: #e4e7ed;
                        // background: url('~@/assets/images/logistics/wuliuyuandian-2.jpg') center center no-repeat;
                        position: absolute;
                        left: 0;
                        top: 25px;
                    }
                    &:nth-of-type(3) {
                        color: #333;
                        font-weight: 700;
                    }
                }
                &:nth-of-type(1){
                    color: #00a0e9;
                    span{
                        &:nth-of-type(2) {
                            background-color: #0bbd87;
                            // background: url('~@/assets/images/logistics/wuliuyuandian-1.jpg') no-repeat right;
                        }
                    }
                }
            }
        }
    }
}
</style>