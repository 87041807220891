<template>
    <div class="m-logistics">
        <div class="m-logistics-header">
            <div class="logo"><img src="favicon.png" alt=""></div>
            <div class="title">
                <p>{{ globalOptions.title }}</p>
                <p>{{ globalOptions.subTitleEn }}</p>
            </div>
        </div>
        <div class="m-logistics-content">
            <TimeLine />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import TimeLine from './TimeLine.vue';

export default {
    name: 'MLogistics',
    components: {
        TimeLine,
    },
    computed: {
      ...mapState ({
          globalOptions: state => state.globalOptions,
      }),
    }
}
</script>

<style lang="scss" scoped>
.m-logistics{
    width: 100vw;
    height: 100vh;
    background-color: #f8f8f8;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    &-header{
        width: 100vw;
        padding: 0.2rem 0.4rem;
        background-color: #97c0f3;
        box-sizing: border-box;
        .logo{
            width: 3rem;
            img{
                width: 100%;
                height: 100%;
                object-fit: fill;
            }
        }
        .title{
            p{
                &:first-child{
                    margin-bottom: 5px;
                    font-size: 0.3rem;
                    font-weight: 700;
                }
                &:last-child{
                    color: #666;
                    font-size: 0.22rem;
                }
            }
        }
    }
    &-content{
        margin-top: 10px;
        padding: 10px 10px;
        background-color: #fff;
        box-sizing: border-box;
    }
}
</style>